var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sign-container" },
    [
      _c("div", { staticClass: "sign-wrap" }, [
        _c(
          "div",
          { staticClass: "code-btn" },
          [
            _vm.column.hasBillStatus && _vm.column.codeCheckIn === 0
              ? _c(
                  "en-image-setting",
                  {
                    attrs: { name: "erweima", size: "small", fill: "#abb7c7" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.handleQRCode.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("签到二维码")]
                )
              : _c("div", { staticStyle: { height: "32px" } }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "sign-btn",
            class:
              _vm.column.hasBillStatus && !_vm.column.disableBtn
                ? ""
                : "disabled",
          },
          [
            _c(
              "div",
              {
                staticClass: "sign-btn-wrap",
                on: { click: _vm.handleSignInClick },
              },
              [
                _c(
                  "div",
                  { staticClass: "icon-bg" },
                  [
                    _c("en-icon", {
                      attrs: {
                        name: "kaoqin",
                        size: "normal",
                        color: "#ffffff",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "span",
                  { class: _vm.signMsg === "点击签到" ? "active" : "" },
                  [_vm._v(_vm._s(_vm.signMsg))]
                ),
              ]
            ),
          ]
        ),
      ]),
      _vm.column.hasBillStatus
        ? _c(
            "div",
            {
              staticClass: "log-title",
              class: _vm.show ? "expand" : "",
              on: { click: _vm.expandWrap },
            },
            [
              _c("div", { staticClass: "log-info" }, [
                _vm._v(" 应到"),
                _c("span", { staticClass: "yingdao" }, [
                  _vm._v(_vm._s(_vm.statis.reachCount)),
                ]),
                _vm._v("人， 实到"),
                _c("span", { staticClass: "shidao" }, [
                  _vm._v(_vm._s(_vm.statis.realityCount)),
                ]),
                _vm._v("人， 未到"),
                _c("span", { staticClass: "weidao" }, [
                  _vm._v(_vm._s(_vm.statis.notCount)),
                ]),
                _vm._v("人， 签到率"),
                _c("span", { staticClass: "qiandaolv" }, [
                  _vm._v(
                    _vm._s(_vm._f("fixRate")(_vm.statis.singInRate)) + "%"
                  ),
                ]),
              ]),
              _c("en-icon", {
                attrs: { name: _vm.show ? "zhankai1" : "shouqi", size: "mini" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.column.hasBillStatus
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.show,
                  expression: "show",
                },
              ],
              staticClass: "log-wrap",
            },
            [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.tabClick },
                  model: {
                    value: _vm.activeTab,
                    callback: function ($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "签到记录", name: "first" } },
                    [
                      _c(
                        "en-table",
                        {
                          ref: "recordTable",
                          attrs: { data: _vm.recordData },
                          on: { "filter-sort-change": _vm.handleRecordFilter },
                        },
                        [
                          _c("en-table-column", {
                            attrs: { type: "index", width: "50" },
                          }),
                          _vm._l(_vm.recordColumn, function (itm, idx) {
                            return _c("en-table-column", {
                              key: idx,
                              attrs: { data: itm, "filter-sort-config": itm },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return [
                                        itm.field === "attachment"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "attachment-wrap",
                                              },
                                              _vm._l(
                                                row.attachment,
                                                function (citm, cidx) {
                                                  return _c("en-image", {
                                                    key: cidx,
                                                    attrs: {
                                                      src: citm.url,
                                                      isPreview: false,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.viewImage(
                                                          cidx,
                                                          row.attachment
                                                        )
                                                      },
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            )
                                          : itm.field === "singinTime"
                                          ? _c(
                                              "div",
                                              { staticClass: "self-td" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("formatTime")(
                                                      row.singinTime
                                                    )
                                                  )
                                                ),
                                              ]
                                            )
                                          : itm.field === "createName"
                                          ? _c("div", [
                                              _c(
                                                "div",
                                                { staticClass: "create-name" },
                                                [
                                                  _c("en-user-logo", {
                                                    attrs: {
                                                      userName:
                                                        row.createName.substr(
                                                          0,
                                                          1
                                                        ),
                                                      size: "30",
                                                    },
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "5px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(row.createName)
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ])
                                          : _c(
                                              "div",
                                              { staticClass: "self-td" },
                                              [_vm._v(_vm._s(row[itm.field]))]
                                            ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            })
                          }),
                        ],
                        2
                      ),
                      _c("en-pagination", {
                        attrs: { "page-model": _vm.recordPageModel },
                        on: { change: _vm.handleRecordPageChange },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "签到人员", name: "second" } },
                    [
                      _c(
                        "en-table",
                        {
                          ref: "personTable",
                          attrs: { data: _vm.personData },
                          on: { "filter-sort-change": _vm.handlePersonFilter },
                        },
                        [
                          _c("en-table-column", {
                            attrs: { type: "index", width: "50" },
                          }),
                          _vm._l(_vm.personColumn, function (itm, idx) {
                            return _c("en-table-column", {
                              key: idx,
                              attrs: {
                                field: itm.field,
                                title: itm.name,
                                "filter-sort-config": itm,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return [
                                        itm.field === "status"
                                          ? _c("div", [
                                              _c(
                                                "div",
                                                {
                                                  class: [
                                                    "status-item",
                                                    row.status === 0
                                                      ? "signin"
                                                      : "",
                                                  ],
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(row["STATUSNAME"])
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : itm.field === "createName"
                                          ? _c("div", [
                                              _c(
                                                "div",
                                                { staticClass: "create-name" },
                                                [
                                                  _c("en-user-logo", {
                                                    attrs: {
                                                      userName:
                                                        row.createName.substr(
                                                          0,
                                                          1
                                                        ),
                                                      size: "30",
                                                    },
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "5px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(row.createName)
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ])
                                          : _c("div", [
                                              _vm._v(_vm._s(row[itm.field])),
                                            ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            })
                          }),
                        ],
                        2
                      ),
                      _c("en-pagination", {
                        attrs: { "page-model": _vm.personPageModel },
                        on: { change: _vm.handlePersonPageChange },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.qrCodeVisible
        ? _c("PopQRCode", {
            attrs: {
              visible: _vm.qrCodeVisible,
              mainId: _vm.businessData.id,
              templateId: _vm.column.templateId,
            },
            on: {
              "update:visible": function ($event) {
                _vm.qrCodeVisible = $event
              },
            },
          })
        : _vm._e(),
      _c("PopSignInExplain", {
        ref: "explainMod",
        attrs: { attr: _vm.column },
        on: { handleSignExplain: _vm.handleExplain },
      }),
      _c("PopPcConfirm", { ref: "pcMod", on: { next: _vm.triggerSignIn } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }