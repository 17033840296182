var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      staticClass: "pc-confirm-alert",
      attrs: { visible: _vm.visible, width: "500px" },
      on: {
        close: function ($event) {
          _vm.visible = false
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "tips" },
        [
          _c("en-icon", { attrs: { name: "tishi", color: "#3e90fe" } }),
          _c("span", [_vm._v("PC端签到将不会记录您的位置，是否继续签到？")]),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("en-button", { on: { click: _vm.handleConfirm } }, [
            _vm._v("确定"),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }