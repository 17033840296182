<!--
 * @Author: pengyu
 * @Date: 2020-08-05 14:43:13
 * @LastEditors: pengyu
 * @LastEditTime: 2020-12-29 15:41:31
 * @Description: pc签到 确认
-->
<template>
  <en-dialog
    :visible="visible"
    width="500px"
    @close="visible=false"
    class="pc-confirm-alert"
  >
    <div class="tips">
      <en-icon name="tishi" color="#3e90fe"></en-icon>
      <span>PC端签到将不会记录您的位置，是否继续签到？</span>
    </div>
    <div slot="footer">
      <en-button @click="handleConfirm">确定</en-button>
    </div>
  </en-dialog>
</template>

<script>
export default {
  name: "PopPcConfirm",
  data() {
    return {
      visible: false
    };
  },
  methods: {
    // 确定
    handleConfirm() {
      this.visible = false;
      this.$emit("next");
    }
  }
};
</script>

<style lang='scss' scoped>
.pc-confirm-alert{
  .tips{
    height: 70px;
    line-height: 70px;
    .en-icon{
      vertical-align: middle;
    }
    span{
      margin-left: 10px;
      vertical-align: middle;
      font-weight: 600;
    }
  }
}
</style>
