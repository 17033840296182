var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      staticClass: "sign-in-alert",
      attrs: { visible: _vm.visible, title: "考勤说明", width: "630px" },
      on: {
        close: function ($event) {
          _vm.visible = false
        },
      },
    },
    [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "first" }, [
          _c(
            "div",
            { staticClass: "time" },
            [
              _c("en-icon", {
                attrs: { name: "shijian-xuanxiang", size: "small" },
              }),
              _c("span", [_vm._v(_vm._s(_vm.time))]),
            ],
            1
          ),
          _c("div", { staticClass: "addr" }),
        ]),
        _c(
          "div",
          { staticClass: "instruction" },
          [
            _c("el-input", {
              attrs: { type: "textarea", placeholder: "请输入备注" },
              model: {
                value: _vm.remark,
                callback: function ($$v) {
                  _vm.remark = $$v
                },
                expression: "remark",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "file" },
          [
            _vm.attr.isPhotograph === 0
              ? _c(
                  "div",
                  { staticClass: "file-tips" },
                  [
                    _c("en-icon", {
                      attrs: {
                        name: "gaoqingtupian",
                        size: "small",
                        color: "#999999",
                      },
                    }),
                    _c("span", [_vm._v("请使用手机端的拍照上传图片")]),
                  ],
                  1
                )
              : _vm._e(),
            _vm.attr.isPhotograph === 1
              ? _c(
                  "div",
                  { staticClass: "file-list" },
                  _vm._l(_vm.attachment, function (itm, idx) {
                    return _c(
                      "div",
                      {
                        key: idx,
                        staticClass: "img-item",
                        on: {
                          click: function ($event) {
                            return _vm.viewImage(idx)
                          },
                        },
                      },
                      [
                        _c("en-image", { attrs: { src: itm.src } }),
                        _c("en-icon", {
                          attrs: { name: "shanchu-liebiao", size: "small" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.deleteImg(idx)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm.attr.isPhotograph === 1
              ? _c(
                  "el-upload",
                  {
                    attrs: {
                      accept: _vm.upextStr,
                      data: _vm.uploadParams,
                      name: "storage_file",
                      action: _vm.urlParam.server,
                      "on-success": _vm.handleUpload,
                      "show-file-list": false,
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "file-btn" },
                      [
                        _c("en-icon", {
                          attrs: { name: "gaoqingtupian", size: "small" },
                        }),
                        _c("span", [_vm._v("添加图片")]),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [_c("en-button", { on: { click: _vm.submit } }, [_vm._v("确定")])],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }