<!--
 * @Author: pengyu
 * @Date: 2020-07-28 18:52:57
 * @LastEditors: pengyu
 * @LastEditTime: 2021-07-02 16:53:05
 * @Description: 签到控件
-->
<template>
  <div class="sign-container">
    <!-- v-show="column.editable" -->
    <div class="sign-wrap">
      <div class="code-btn">
        <en-image-setting
          v-if="column.hasBillStatus && column.codeCheckIn===0"
          name="erweima"
          size="small"
          fill="#abb7c7"
          @click.native="handleQRCode"
        >签到二维码</en-image-setting>
        <div style="height:32px" v-else></div>
      </div>
      <div class="sign-btn" :class="column.hasBillStatus && !column.disableBtn ? '': 'disabled'">
        <div class="sign-btn-wrap" @click="handleSignInClick">
          <div class="icon-bg">
            <en-icon
              name="kaoqin"
              size='normal'
              color="#ffffff"
            ></en-icon>
          </div>
          <span :class="signMsg==='点击签到' ? 'active': ''">{{signMsg}}</span>
        </div>
      </div>
    </div>
    <div class="log-title"
      v-if="column.hasBillStatus"
      @click="expandWrap"
      :class="show ? 'expand' : ''"
    >
      <div class="log-info">
        应到<span class="yingdao">{{ statis.reachCount }}</span>人，
        实到<span class="shidao">{{statis.realityCount}}</span>人，
        未到<span class="weidao">{{statis.notCount}}</span>人，
        签到率<span class="qiandaolv">{{statis.singInRate | fixRate}}%</span>
      </div>
      <en-icon :name="show ?'zhankai1':'shouqi'" size="mini"></en-icon>
    </div>
    <div class="log-wrap"
      v-if="column.hasBillStatus"
      v-show="show">
      <el-tabs
        v-model="activeTab"
        @tab-click="tabClick"
      >
        <el-tab-pane label="签到记录" name="first">
          <en-table
            :data="recordData"
            ref="recordTable"
            @filter-sort-change="handleRecordFilter"
          >
            <en-table-column type="index" width="50"></en-table-column>
            <en-table-column
              v-for="(itm,idx) in recordColumn"
              :key="idx"
              :data="itm"
              :filter-sort-config="itm"
            >
              <template v-slot="{row}">
                <div v-if="itm.field==='attachment'" class="attachment-wrap">
                  <en-image
                    v-for="(citm,cidx) in row.attachment"
                    :key="cidx"
                    :src="citm.url"
                    :isPreview="false"
                    @click.prevent="viewImage(cidx, row.attachment)"
                  ></en-image>
                </div>
                <div v-else-if="itm.field==='singinTime'" class="self-td">{{row.singinTime | formatTime}}</div>
                <div v-else-if="itm.field==='createName'">
                  <div class="create-name">
                    <en-user-logo :userName="row.createName.substr(0,1)" size="30"></en-user-logo>
                    <span style="margin-left: 5px;">{{row.createName}}</span>
                  </div>
                </div>
                <div v-else class="self-td">{{row[itm.field]}}</div>
              </template>
            </en-table-column>
          </en-table>
          <en-pagination :page-model="recordPageModel" @change="handleRecordPageChange"></en-pagination>
        </el-tab-pane>
        <el-tab-pane label="签到人员" name="second">
          <en-table
            :data="personData"
            ref="personTable"
            @filter-sort-change="handlePersonFilter"
          >
            <en-table-column type="index" width="50"></en-table-column>
            <en-table-column
              v-for="(itm,idx) in personColumn"
              :key="idx"
              :field="itm.field"
              :title="itm.name"
              :filter-sort-config="itm"
            >
              <template v-slot="{row}">
                <div v-if="itm.field==='status'">
                  <div :class="['status-item', row.status===0?'signin':'']">{{row["STATUSNAME"]}}</div>
                </div>
                <div v-else-if="itm.field==='createName'">
                  <div class="create-name">
                    <en-user-logo :userName="row.createName.substr(0,1)" size="30"></en-user-logo>
                    <span style="margin-left: 5px;">{{row.createName}}</span>
                  </div>
                </div>
                <div v-else>{{row[itm.field]}}</div>
              </template>
            </en-table-column>
          </en-table>
          <en-pagination :page-model="personPageModel" @change="handlePersonPageChange"></en-pagination>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 签到二维码 -->
    <PopQRCode
      v-if="qrCodeVisible"
      :visible="qrCodeVisible"
      :mainId="businessData.id"
      :templateId="column.templateId"
      @update:visible="qrCodeVisible=$event"
    ></PopQRCode>
    <!-- 签到说明 -->
    <PopSignInExplain
      ref="explainMod"
      :attr="column"
      @handleSignExplain="handleExplain"
    ></PopSignInExplain>
    <!-- pc签到确认 -->
    <PopPcConfirm
      ref="pcMod"
      @next="triggerSignIn"
    >
    </PopPcConfirm>
  </div>
</template>

<script>
import { Message } from "element-ui";
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import { request } from "en-js";
import { businessService } from "@/api/businessModel";
import PopQRCode from "./components/popQRCode.vue";
import PopSignInExplain from "./components/popSignInExplain";
import PopPcConfirm from "./components/popPcConfirm";

export default {
  name: "BMSignColumn",
  props: {
    column: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: { PopQRCode, PopSignInExplain, PopPcConfirm },
  filters: {
    fixRate(val) {
      return (Number(val || 0) * 100).toFixed(2);
    },
    formatTime(val) {
      return val ? dayjs(val).format("YYYY-MM-DD HH:mm") : "";
    }
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  mounted() {
    this.signMsg = this.column.hasBillStatus ? "点击签到" : "单据提交后即可签到";
  },
  data() {
    return {
      signMsg: "",
      show: false,
      activeTab: "first",
      statis: {
        notCount: "0",
        reachCount: "0",
        realityCount: "0",
        singInRate: "0.0000"
      }, // 统计信息
      recordData: [], // 签到记录数据
      personData: [], // 签到人员数据
      recordPageModel: {
        pageNo: 1,
        pageSize: 10,
        total: "",
        totalPages: ""
      }, // 签到记录分页参数
      personPageModel: {
        pageNo: 1,
        pageSize: 10,
        total: "",
        totalPages: ""
      }, // 签到人员分页参数
      recordColumn: [
        {
          field: "createName", name: "签到人", type: "001", filterable: true
        },
        {
          field: "deptName", name: "签到人部门", type: "001", filterable: true
        },
        {
          field: "singinTime", name: "签到时间", type: "007", timeFormat: 9, filterable: true, filterKey: "singinTime"
        },
        {
          field: "address", name: "签到地点", type: "001", filterable: true
        },
        {
          field: "remark", name: "备注", type: "001", filterable: true
        },
        {
          field: "attachment", name: "图片"
        }
      ], // 签到记录表格列
      personColumn: [
        {
          field: "createName", name: "签到人", type: "001", filterable: true
        },
        {
          field: "deptName", name: "签到人部门", type: "001", filterable: true
        },
        {
          field: "status", name: "状态", type: "001", filterable: true
        }
      ], // 签到人员表格列
      qrCodeVisible: false
    };
  },
  created() {
    this.querySignData();
  },
  methods: {
    /**
     * @description: 查询表格数据
     */
    querySignData() {
      if (this.column.hasBillStatus) {
        this.querySingInList();
        this.querySingPersonList();
      }
    },
    /**
     * @description: 查询签到记录
     */
    async querySingInList() {
      const pa = {
        mainId: this.businessData.id,
        templateId: this.column.templateId
      };
      Object.assign(pa, this.recordPageModel);
      const rsp = await businessService.querySingInList(pa);
      this.recordData = rsp.records;
      this.recordData.forEach((itm) => {
        if (itm.createId === this.userInfo.userId) {
          this.column.isSignIn = true;
          this.signMsg = "已签到";
        }
      });
      this.recordPageModel.total = rsp.total;
      this.recordPageModel.totalPages = rsp.totalPages;
    },
    /**
     * @description: 查询签到人员
     */
    async querySingPersonList() {
      const pa = {
        mainId: this.businessData.id,
        templateId: this.column.templateId
      };
        // 查询签到人员
      Object.assign(pa, this.personPageModel);
      const rsp = await businessService.querySingPersonList(pa);
      this.statis = { ...rsp.params };
      this.personData = rsp.records;
      this.personPageModel.total = rsp.total;
      this.personPageModel.totalPages = rsp.totalPages;
    },
    /**
     * @description: 查询表格数据
     */
    handleRecordPageChange({ pageSize, pageNo }) {
      this.recordPageModel.pageSize = pageSize;
      this.recordPageModel.pageNo = pageNo;
      this.querySingInList();
    },
    /**
     * @description: 查询表格数据
     */
    handlePersonPageChange({ pageSize, pageNo }) {
      this.recordPageModel.pageSize = pageSize;
      this.recordPageModel.pageNo = pageNo;
      this.querySingPersonList();
    },
    /**
     * @description: 二维码签到
     */
    handleQRCode() {
      this.qrCodeVisible = true;
    },
    /**
     * @description: 签到
     */
    handleSignInClick() {
      const { disabled } = this.column;
      if (disabled || !this.column.hasBillStatus) {
        return;
      }

      const { isSignIn, signInSecond } = this.column;
      if (isSignIn && signInSecond === 1) { // 已签到 且 仅一次
        Message("您已签到，请勿重复操作！");
        return;
      }

      this.$refs.pcMod.visible = true;
    },
    /**
     * @description: pc签到确认 回调
     */
    triggerSignIn() {
      const { requrein, picRequrein } = this.column;
      if (requrein === 1 && picRequrein === 1) {
        this.signIn(); // 签到
      } else {
        this.signInWithInfo(); // 签到说明
      }
    },
    /**
     * @description: 签到
     * @param param
     */
    @request()
    async signIn(param = {}) {
      let pa = this.getParamSign();
      pa = { ...pa, ...param };
      await businessService.mdSignIn(pa);
      Message({
        message: "签到成功",
        type: "success"
      });
      this.signMsg = "已签到";
      this.querySignData(); // 重新查询签到列表
      this.column.isSignIn = true;
    },
    /**
     * @description: 签到说明
     */
    signInWithInfo() {
      this.$refs.explainMod.visible = true;
    },
    /**
     * @description: 签到 获取参数
     */
    getParamSign() {
      // 应到人员
      const { signInField, templateId } = this.column;
      const signFieldColumn = this.column.findMainAreaField(signInField);
      const singInTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
      let shouldSignInList = "";
      if (signFieldColumn && signFieldColumn.columnValue && signFieldColumn.columnValue.id) {
        shouldSignInList = JSON.stringify([signFieldColumn.columnValue]);
      }
      return {
        templateId,
        mainId: this.businessData.id,
        singInTime,
        shouldSignInList
      };
    },
    /**
     * @description: 签到说明 回调
     * @param obj 签到说明 参数
     */
    handleExplain(obj) {
      this.signIn(obj);
    },
    /**
     * @description: 展开收起列表
     */
    expandWrap() {
      this.show = !this.show;
      this.$refs.recordTable.refreshColumn();
    },
    /**
     * @description: tab切换 调用列表refreshColumn方法
     */
    tabClick() {
      this.$refs.recordTable.refreshColumn();
      this.$refs.personTable.refreshColumn();
    },
    /**
     * @description: 签到记录表格筛选
     */
    handleRecordFilter({ filterData }) {
      this.recordPageModel.filterFields = filterData || "";
      this.recordPageModel.pageNo = 1;
      this.querySingInList();
    },
    /**
     * @description: 签到人员表格筛选
     */
    handlePersonFilter({ filterData }) {
      this.personPageModel.filterFields = filterData || "";
      this.personPageModel.pageNo = 1;
      this.querySingPersonList();
    },
    /**
     * @description: 查看大图
     * @param index
     * @param list
     */
    // eslint-disable-next-line no-unused-vars
    viewImage(index, list) {
      this.$viewImage({
        datas: list,
        url: "url",
        thumUrl: "url",
        initialViewIndex: index
      });
    }
  }
};
</script>

<style lang='scss' scoped>
.sign-container{
  width: 100%;
  border: 1px solid $field-border-color;
  border-radius: 5px;
  .sign-wrap{
    min-height: 166px;
    .code-btn{
      text-align: right;
      padding: 0 20px;
    }
    .sign-btn{
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 12px;
      .sign-btn-wrap{
        width: 110px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        .icon-bg{
          width: 80px;
          height: 80px;
          border-radius: 50%;
          line-height: 100px;
          text-align: center;
          background: #3e90fe;
        }

        span{
          color: #999999;
          &.active{
            color: #3e90fe;
          }
        }
      }
      &.disabled .icon-bg{
        background: rgba(70,148,223,0.5);
      }
    }
  }
  .log-title{
    height: 50px;
    line-height: 50px;
    background: #F6FAFD;
    padding: 0 20px;
    border-top: 1px solid $field-border-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.expand{
      border-bottom: 1px solid $field-border-color;
      .en-icon{
        color: #3e90fe;
      }
    }
    .log-info span{
      font-weight: 600;
    }
    .yingdao{
      color: #4B99E2;
      font-size: 14px;
    }
    .shidao,.qiandaolv{
      color: #2FC696;
      font-size: 14px;
    }
    .weidao{
      color: #FE717A;
      font-size: 14px;
    }
    .en-icon{
      color: #a9b5c6;
    }
  }
  .en-table th {
    display: table-cell !important;
  }
  .log-wrap{
    padding: 0 20px;
    & /deep/ .el-tabs__active-bar{
      width: 100px;
    }
    & /deep/ .el-tabs__item{
      width: 100px;
      text-align: center;
      padding: 0;
    }
    & /deep/ .el-tab-pane{
      padding-top: 10px;
    }
  }
  .attachment-wrap{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .el-image{
      width: 32px;
      height: 32px;
      border-radius: 2px;
      margin-right: 5px;
    }
  }
  .status-item{
    width: 64px;
    height: 28px;
    text-align: center;
    line-height: 28px;
    border-radius: 5px;
    background: rgba(247,107,107,0.1);
    color: rgb(247,107,107);
    &.signin{
      background: rgba(38,195,147,0.1);
      color: rgb(38,195,147);
    }
  }
}
</style>
