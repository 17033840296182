var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      staticClass: "qr-code-dialog",
      attrs: { visible: _vm.visible, width: "420px" },
      on: { close: _vm.close },
    },
    [
      _c(
        "div",
        { attrs: { slot: "title" }, slot: "title" },
        [
          _c("span", [_vm._v("签到二维码")]),
          _c(
            "en-image-setting",
            {
              attrs: { name: "gengxin-kuaijiecaozuo", color: "#36DEA8" },
              nativeOn: {
                click: function ($event) {
                  return _vm.reset.apply(null, arguments)
                },
              },
            },
            [_vm._v("更新")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "code-img" }, [
        _c("div", { ref: "qrcode", attrs: { id: "qrcode" } }),
        _c("div", { staticClass: "logo" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/images/enlogo.png"),
              alt: "",
              width: "100%",
            },
          }),
        ]),
      ]),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("en-button", { on: { click: _vm.printQRCode } }, [
            _vm._v("打印二维码"),
          ]),
        ],
        1
      ),
      _c("iframe", {
        staticStyle: { display: "none" },
        attrs: { id: "iframe" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }