<!--
 * @Author: pengyu
 * @Date: 2020-07-30 09:04:20
 * @LastEditors: pengyu
 * @LastEditTime: 2020-07-31 10:44:44
 * @Description: 签到二维码
-->
<template>
  <en-dialog
    :visible="visible"
    width="420px"
    @close="close"
    class="qr-code-dialog"
  >
    <div slot="title">
      <span>签到二维码</span>
      <en-image-setting
        name="gengxin-kuaijiecaozuo"
        color="#36DEA8"
        @click.native="reset"
      >更新</en-image-setting>
    </div>
    <div class="code-img">
      <div id="qrcode" ref="qrcode"></div>
      <div class="logo">
        <img src="@/assets/images/enlogo.png" alt="" width="100%">
      </div>
    </div>
    <div slot="footer">
      <en-button @click="printQRCode">打印二维码</en-button>
    </div>
    <iframe id="iframe" style="display: none;"></iframe>
  </en-dialog>
</template>

<script>
import QRCode from "qrcodejs2";
import { businessService } from "@/api/businessModel";

export default {
  name: "PopQRCode",
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    templateId: { // 模板id
      type: String,
      default: ""
    },
    mainId: { // 数据id
      type: String,
      default: ""
    }
  },
  created() {
    this.querySignCode();
  },
  methods: {
    /**
     * @description: 查询签到二维码
     */
    async querySignCode() {
      const pa = {
        mainId: this.mainId,
        templateId: this.templateId
      };
      const rsp = await businessService.querySignInCode(pa);
      this.initQRCode(rsp);
    },
    /**
     * @description: 渲染二维码
     */
    initQRCode(rsp) {
      const qrcode = new QRCode("qrcode", {
        width: 260, // 图像宽度
        height: 260, // 图像高度
        colorDark: "#000000", // 前景色
        colorLight: "#ffffff", // 背景色
        typeNumber: 4,
        correctLevel: QRCode.CorrectLevel.H // 容错级别 容错级别有：（1）QRCode.CorrectLevel.L （2）QRCode.CorrectLevel.M （3）QRCode.CorrectLevel.Q （4）QRCode.CorrectLevel.H
      });
      qrcode.makeCode(rsp.signinCode || "空白"); // 生成另一个新的二维码
      this.qrcode = qrcode;
    },
    /**
     * @description: 关闭弹窗
     */
    close() {
      this.$emit("update:visible", false);
    },
    /**
     * @description: 刷新二维码
     */
    async reset() {
      const pa = {
        mainId: this.mainId,
        templateId: this.templateId
      };
      const rsp = await businessService.refreshSignInCode(pa);
      this.qrcode.clear(); // 清除二维码
      this.qrcode.makeCode(rsp.signinCode);
    },
    /**
     * @description: 打印二维码
     */
    printQRCode() {
      const qrcode = this.$refs.qrcode;
      const iframe = window.frames[0];
      iframe.document.body.innerHTML = qrcode.innerHTML; // 把传过来的html给iframe <body>
      iframe.window.print();
    }
  }
};
</script>

<style lang='scss' scoped>
.qr-code-dialog{
  & /deep/ .el-dialog__header>div{
    display: flex;
    justify-content: space-between;
    .en-image-setting{
      margin-right: 30px;
    }
  }
  .code-img{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 310px;
    position: relative;
    .logo{
      width: 70px;
      height: 70px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      background: #ffffff;
      position: absolute;
    }
  }
  #qrcode{
    padding: 10px;
    border: 1px solid #e8ecf2;
    width: 280px;
    height: 280px;
  }
  & /deep/ .el-dialog__footer>div{
    text-align: center;
  }
}
</style>
