<!--
 * @Author: pengyu
 * @Date: 2020-07-30 14:40:24
 * @LastEditors: pengyu
 * @LastEditTime: 2021-07-28 15:20:23
 * @Description: 签到说明
-->
<template>
  <en-dialog
    :visible="visible"
    title="考勤说明"
    width="630px"
    class="sign-in-alert"
    @close="visible=false"
  >
    <div class="content">
      <div class="first">
        <div class="time">
          <en-icon name="shijian-xuanxiang" size="small"></en-icon>
          <span>{{time}}</span>
        </div>
        <div class="addr">
          <!-- <en-icon name="dizhi-kaoqin" size="small"></en-icon>
          <span>进行中路127号</span> -->
        </div>
      </div>
      <div class="instruction">
        <el-input v-model="remark" type="textarea" placeholder="请输入备注"></el-input>
      </div>
      <div class="file">
        <!-- 仅拍照上传 -->
        <div class="file-tips" v-if="attr.isPhotograph===0">
          <en-icon name="gaoqingtupian" size="small" color="#999999"></en-icon>
          <span>请使用手机端的拍照上传图片</span>
        </div>
        <!-- 上传图片 -->
        <div class="file-list" v-if="attr.isPhotograph===1">
          <div class="img-item"
            v-for="(itm, idx) in attachment"
            :key="idx"
            @click="viewImage(idx)"
          >
            <en-image
              :src="itm.src"
            ></en-image>
            <en-icon
              name="shanchu-liebiao"
              size="small"
              @click.native="deleteImg(idx)"
            ></en-icon>
          </div>
        </div>
        <el-upload
          v-if="attr.isPhotograph===1"
          :accept="upextStr"
          :data="uploadParams"
          name="storage_file"
          :action="urlParam.server"
          :on-success="handleUpload"
          :show-file-list="false"
        >
          <div class="file-btn">
            <en-icon name="gaoqingtupian" size="small"></en-icon>
            <span>添加图片</span>
          </div>
        </el-upload>
      </div>
    </div>
    <div slot="footer">
      <en-button @click="submit">确定</en-button>
    </div>
  </en-dialog>
</template>

<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";

export default {
  name: "PopSignInExplain",
  props: {
    attr: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters(["urlParam"])
  },
  watch: {
    visible(nVal) {
      if (nVal) {
        this.time = dayjs().format("HH:mm");
        this.uploadParams = {
          appKey: this.urlParam.appKey,
          token: this.urlParam.token,
          ts: this.urlParam.ts
        };
      } else {
        Object.assign(this.$data, this.$options.data());
      }
    }
  },
  data() {
    return {
      visible: false,
      remark: "",
      attachment: [],
      time: "",
      upextStr: ".gif,.jpg,.jpeg,.bmp,.png",
      uploadParams: {}
    };
  },
  methods: {
    // 确定
    submit() {
      const obj = {
        remark: this.remark,
        attachment: JSON.stringify(this.attachment)
      };
      this.visible = false;
      this.$emit("handleSignExplain", obj);
    },
    /**
     * @description: 文件上传成功钩子
     */
    handleUpload(response, file) {
      try {
        const { data } = response;
        const suffix = file.name.replace(/.+\./, "");
        const atta = {
          src: data.fileUrl,
          code: data.fileCode,
          extension: suffix,
          name: file.name,
          attachmentSize: file.size,
          type: "000"
        };

        this.attachment.push(atta);
      } catch (error) {
        console.log(`%c${error}`, "color:red");
      }
    },
    /**
     * @description: 删除图片
     */
    deleteImg(index) {
      this.attachment.splice(index, 1);
    },
    /**
     * @description: 查看图片
     * @param index
     */
    viewImage(index) {
      this.$viewImage({
        datas: this.attachment,
        url: "src",
        thumUrl: "src",
        initialViewIndex: index
      });
    }
  }
};
</script>

<style lang='scss' scoped>
.sign-in-alert{
  .content{
    padding: 0px;
    min-height: 220px;
    font-size: 12px;
    .first{
      display: flex;
      padding-bottom:10px;
    }
    .time,.addr,.file-btn,.file-tips{
      margin-right: 20px;
      .en-icon{
        vertical-align: middle;
      }
      span{
        margin-left: 10px;
        vertical-align: middle;
      }
    }
    .file-tips {
      text-align: left;
      padding-top: 20px;
      cursor: not-allowed;
      span{
        color: #999999;
      }
    }
    .file-list{
      padding-top: 20px;
      display: flex;
      .img-item{
        width: 60px;
        height: 60px;
        // overflow: hidden;
        position: relative;
        margin-right: 10px;
        &:hover{
          .en-icon{
            display: block;
          }
        }
        .el-image{
          border-radius: 5px;
        }
        .en-icon{
          display: none;
          position: absolute;
          top: -8px;
          right: -8px;
        }
      }
    }
    .file-list+div{
      text-align: left;
    }
  }

}
</style>
